<style>
  @import url('../assets/css/datospersonales.css');
</style>
<template>
  <div class="container">
    <h3 class="title">¡BIEN HECHO!</h3>
    <h1 class="subtitle" style="font-weight: 900; color: var(--tertiary-color)">
      Un último 
      <span class='subrayado'>paso más</span>
    </h1>
    <br>
    <p class="descripcion" style="font-weight: 700; color: var(--tertiary-color)">Completa tus datos y continua para descubrir tu nivel</p>
    <br>
    <div class="texto">
      ALGUNOS DATOS SOBRE TI
    </div>
 
    <form @submit.prevent="submitForm">
      <div id="form">
        <div class="form-group">
          <input type="text" v-model="nombre" placeholder="Nombre*" class="dtinput rounded-border">
          <input type="text" v-model="apellidos" placeholder="Apellidos*" class="dtinput rounded-border">
        </div>
        <div class="form-group">
          <input type="text" v-model="correo" placeholder="Correo Electronico*" class ="dtinput rounded-border">
          <input type="text" v-model="telefono" placeholder="Telefono*" class="dtinput rounded-border" maxlength="9">
        </div>
        <div class="priva">
          <input type="checkbox" id="privacy" v-model="acceptedPrivacy">
          <label for="privacy">He leído y acepto <a id="privacy-policy">la política de privacidad</a> </label>
        </div>
      </div>
      <button type="submit" id="submit-btn" :disabled="!formIsValid">CONTINUAR Y SABER MI NIVEL</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nombre: '',
      apellidos: '',
      correo: '',
      telefono: '',
      selectedOption: '',
      acceptedPrivacy: false
    };
  },
   computed: {
    formIsValid() {
      // Verificar si los campos están llenos y si el correo es válido
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const telefonoRegex = /^[0-9]{9}$/;
      console.log(this.nombre !== '' && this.apellidos !== '' && emailRegex.test(this.correo) && this.acceptedPrivacy);
      return this.nombre !== '' && this.apellidos !== '' && emailRegex.test(this.correo) && this.acceptedPrivacy && telefonoRegex.test(this.telefono) ;
    },
    
  },
  methods: {

 submitForm() {
  if (this.formIsValid) {
    // Preparar el objeto de datos
    const formData = {
      name: this.nombre,
      surname: this.apellidos,
      email: this.correo,
      phonenumber: this.telefono
    };

    // Realizar la solicitud utilizando Axios
      this.$emit('respuestaSeleccionada', formData);
  }
}

  }
}
</script>

