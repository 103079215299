<template>
  <div class="preguntas">
    <BarraProgreso
      v-if="
        !isFinalizado &&
        !cuestionarioFinalizado &&
        componentToShow !== 'DatosPersonales'
      "
      :totalPreguntas="preguntastotales"
      :preguntaActual="preguntaactual"
      :titulo="titulo"
      :duration="3000"
      
      @timeout="timeoutFunction"
    />
    <div
      class="d-flex flex-column justify-content-center align-items-center"
      style="height: 80vh"
      v-if="cuestionarioFinalizado"
    >
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
      <div class="py-2">Cargando tus datos...</div>
    </div>
    <div
      :class="{ 'background-image2': !isFinalizado && !cuestionarioFinalizado }"
    >
      <component
        :is="componentToShow"
        :pregunta="enunciado"
        :respuestas="respuestas"
        :audio="audio"
        @respuestaSeleccionada="eleccionDeRespuesta"
        :planteamiento="planteamiento"
      ></component>
    </div>
  </div>
  <div
    class="pie"
    v-if="
      !isFinalizado &&
      !cuestionarioFinalizado &&
      componentToShow !== 'DatosPersonales'
    "
  >
    <PieDePagina />
  </div>
</template>
 
<script>
import Start from "../components/Start.vue";
import SeleccionMultiple from "../components/SeleccionMultiple.vue";
import Listen from "../components/Listen.vue";
import Writing from "../components/Writing.vue";
import Finalizado from "../components/Finalizado.vue";
import BarraProgreso from "../components/BarraProgreso.vue";
import axios from "axios";
import DatosPersonales from "@/components/DatosPersonales.vue";
import PieDePagina from "../components/PieDePagina.vue";

export default {
  components: {
    Start,
    SeleccionMultiple,
    Listen,
    Writing,
    Finalizado,
    BarraProgreso,
    DatosPersonales,
    PieDePagina,
  },
  data() {
    return {
      pregunta: {},
      componentToShow: "",
      respuestas: [],
      enunciado: "",
      planteamiento:"",
      audio: "",
      titulo: "",
      preguntaactual: 1,
      preguntastotales: 105,
      isFinalizado: false,
      cuestionarioFinalizado: false,
      mostrarSpinner: false,
    };
  },
  mounted() {
    window.addEventListener("beforeunload", function (e) {
      e.preventDefault();
    });
  },
  created() {
    this.cargarPregunta();
  },
  methods: {
    cargarPregunta() {
      const postData = {
          tipo: 0,
        };
      try {
        axios
          .get(process.env.VUE_APP_ENDPOINT + "/pni/startPNI",{
            withCredentials: true,
          })
          .then((response) => {
            console.log("carga");
            console.log(response);
            this.pregunta = response.data;
            console.log("Valor en kg_specificskill:", this.pregunta);
            this.enunciado = response.data.kg_enunciado;
            this.duracion = response.data.kg_duracion;
            this.planteamiento=response.data.kg_planteamiento
            this.respuestas = [
              response.data.kg_respuestacorrecta,
              response.data.kg_distraccion1,
              response.data.kg_distraccion2,
              response.data.kg_distraccion3,
            ];

            if (response.data.hasOwnProperty("kg_audio")) {
              this.audio = response.data.kg_audio;
            } else {
              this.audio = "";
            }

            this.selecciondepreguntas();
          })
          .catch((error) => {
            console.error("Error al cargar el archivo JSON:", error);
          });
      } catch (err) {
        console.log(err);
      }
    },
    timeoutFunction() {
      console.log("Timeout occurred");
      // Call your timeout function here
    },

    
    async eleccionDeRespuesta(respuestaSeleccionada) {
      this.componentToShow = "";
    
      if (respuestaSeleccionada.name) {
        console.log("tiramos un closePNI");
        this.mostrarSpinner = true;
        this.cuestionarioFinalizado = true;
        await axios
          .post(
            process.env.VUE_APP_ENDPOINT + "/pni/closePNI",
            respuestaSeleccionada,
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true, // Establecer withCredentials a true
            }
          )
          .then((response) => {
            if (!response.ok) {
              this.audio = response.data;
              //throw new Error('Hubo un problema al enviar los datos.');
            }
            // Emitir el evento después de que la solicitud se haya completado con éxito
          })
          .catch((error) => {
            console.error("Error:", error);
          });
        this.mostrarSpinner = false;
        this.cuestionarioFinalizado = false;
        this.isFinalizado = true;
        document.body.style.overflow = "auto";
        this.componentToShow = "Finalizado";
      } else {
        console.log(this.pregunta.kg_pregunta);
        const esRespuestaCorrecta =
          respuestaSeleccionada === this.pregunta.kg_respuestacorrecta;
        const postData = {
          isCorrect: esRespuestaCorrecta,
          answer: respuestaSeleccionada,
          kg_pregunta: this.pregunta.kg_pregunta,
          _kg_pregunta_value: this.pregunta.kg_definicionpreguntaid,
          _kg_categoria_value: this.pregunta._kg_categoria_value,
        };

        //console.log(postData);

        await this.$nextTick();
        var anterior = this.pregunta;
        try {
          axios.defaults.withCredentials = true;
          const response = await axios.post(
            process.env.VUE_APP_ENDPOINT + "/question/getNextQuestion",
            postData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const data = response.data;
          console.log(data);
          this.pregunta = data;
          this.enunciado = data.kg_enunciado;
           this.planteamiento=data.kg_planteamiento;
          this.respuestas = [
            data.kg_respuestacorrecta,
            data.kg_distraccion1,
            data.kg_distraccion2,
            data.kg_distraccion3,
          ];
          this.preguntaactual++;

          if (data.hasOwnProperty("kg_audio")) {
            this.audio = data.kg_audio;
          } else {
            this.audio = "";
          }

          if (data.levelName && data.levelId) {
            this.enunciado = data.levelName;
            this.componentToShow = "DatosPersonales";

            return;
          }

          if (anterior.endOfTest === true) {
            this.componentToShow = "DatosPersonales";
            return;
          }

          this.selecciondepreguntas();
        } catch (error) {
          console.error("Error al cargar la pregunta desde la API:", error);
        }
      }
    },
    /* async eleccionDeRespuesta(respuestaSeleccionada) {
 
      this.componentToShow = '';
      await this.$nextTick();
      var anterior= this.pregunta;
      try {
        const esRespuestaCorrecta = respuestaSeleccionada == this.pregunta.kg_respuestacorrecta;
        const postData = {
          correcto: esRespuestaCorrecta,
          respuesta:respuestaSeleccionada,
          idpregunta: this.pregunta.idpregunta ,
          correcta: this.pregunta.kg_respuestacorrecta
        };
        console.log(postData)
        const numeroAleatorio = Math.floor(Math.random() * 3) + 1;
 
        // Realizamos la solicitud para obtener la siguiente pregunta
        const response = await fetch('/data/pregunta'+numeroAleatorio+'.json');
        const data = await response.json();
 
       
        this.pregunta = data;
        this.enunciado = data.kg_enunciado;
        this.respuestas = [
          data.kg_respuestacorrecta,
          data.kg_distraccion1,
          data.kg_distraccion2,
          data.kg_distraccion3
        ];
        this.audio=data.audio
 
if (anterior.endOfTest === true) {
      this.componentToShow = 'Finalizado';
      return;
    }
        this.selecciondepreguntas();
      } catch (error) {
        console.error('Error al cargar el archivo JSON:', error);
      }
    },
*/
    selecciondepreguntas() {
      if (this.componentToShow !== this.pregunta._kg_categoria_value) {
        switch (this.pregunta._kg_categoria_value) {
          case "f94c1397-0ac5-ee11-9079-000d3a24122d":
            this.titulo = "Grammar";
            this.componentToShow = "SeleccionMultiple";

            break;
          case "c5fbd2c1-0ac5-ee11-9079-000d3a24122d":
            this.titulo = "Grammar";
            this.componentToShow = "SeleccionMultiple";
            break;
          case "0a0b2aa3-0ac5-ee11-9079-000d3a24122d":
            this.titulo = "Grammar";
            this.componentToShow = "SeleccionMultiple";
            break;
          case "04bb24a9-0ac5-ee11-9079-000d3a24122d":
            this.titulo = "Listening";
            this.componentToShow = "Listen";
            break;
          case "480364b5-0ac5-ee11-9079-000d3a24122d":
            this.titulo = "Writting";
            this.componentToShow = "Writing";
            break;
          default:
            this.componentToShow = "DatosPersonales";
        }
      }
    },
  },
};
</script>
 
<style>
.background-image.background-visible {
  display: none;
}
@media screen and (max-width: 844px) {
  .background-image2 {
    /*background: url("../../public/img/Sin título-2.png") center center no-repeat;*/
    background-size: cover;
    height: 78.8vh;
    width: 100vw;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    flex-direction: column;
    overflow: scroll;
    position: static;
    bottom: 0;
  }
}

@media screen and (min-width: 845px) {
  .background-image2 {
    /* background: url("../../public/img/Sin título-2.png") center center no-repeat; */
    background-size: cover;
    height: 78.8vh;
    width: 100vw;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    flex-direction: column;
  
  }
}
.pie {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>