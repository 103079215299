<style>
  @import url('../assets/css/seleccionmultiple.css');
</style>
<template>
  <div class="seleccion-multiple">
    <h2 class="grammar">GRAMMAR...</h2>
    <div class="preguntac-container" :style="{ height: preguntaHeight + 'vh' }">
       <div  :style="{ fontSize: fontSize + 'px' }">
        {{ planteamiento }}
      </div>
      <div class="pregunta" ref="container" :style="{ fontSize: fontSize + 'px' }">
        {{ pregunta }}
      </div>
     
    </div>

    <div class="opciones">
      <button
        class="opcion"
        v-for="(opcion, index) in opcionesFiltradas"
        :key="index"
        @click="seleccionarRespuesta(opcion)"
      >
        {{ opcion }}
      </button>
    </div>
  </div>
</template>

<script>
import BarraProgreso from "../components/BarraProgreso.vue";

export default {
  props: {
    pregunta: String,
    changeComponent: Function,
    respuestas: Array,
    audio: String,
    planteamiento: String,
  },
  components: {
    BarraProgreso,
  },
  computed: {
    opcionesFiltradas() {
      // Filtrar las opciones para remover las que son "" o null
      return this.preguntasAleatorias.filter(
        (opcion) => opcion !== "" && opcion !== null
      );
    },
  },
  data() {
    return {
      preguntasAleatorias: [],
      preguntaHeight: 10, // Altura inicial del contenedor de pregunta
      fontSize: 20 // Tamaño inicial de la fuente
    };
  },
  created() {
    this.generarOpcionesAleatorias();
  },
  mounted() {
    this.adjustQuestionHeight();
    window.addEventListener("resize", this.adjustQuestionHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.adjustQuestionHeight);
  },
  methods: {
    adjustQuestionHeight() {
      const container = this.$refs.container;
      if (container) {
        let currentHeight = this.preguntaHeight;
        // Aumentar gradualmente la altura del contenedor hasta que no haya desbordamiento o hasta alcanzar 15vh
        while (currentHeight < 15 && container.scrollHeight > container.offsetHeight) {
          currentHeight += 0.5; // Aumentar la altura en 0.5vh
          this.preguntaHeight = currentHeight;
        }
        // Una vez que la altura se ha ajustado, reducir el tamaño de la fuente si es necesario
        this.reduceFontSize();
      }
    },
    reduceFontSize() {
      const container = this.$refs.container;
      if (container) {
        while (container.scrollHeight > container.offsetHeight) {
          this.fontSize -= 0.5; // Reducir el tamaño de la fuente
          container.style.fontSize = this.fontSize + 'px'; // Aplicar el nuevo tamaño
        }
      }
    },
    generarOpcionesAleatorias() {
      // Copiar y mezclar las respuestas para mostrarlas en orden aleatorio
      this.preguntasAleatorias = [...this.respuestas].sort(
        () => Math.random() - 0.5
      );
    },
    seleccionarRespuesta(respuestaSeleccionada) {
      // Emitir el evento para enviar la respuesta seleccionada a la vista padre
      this.$emit("respuestaSeleccionada", respuestaSeleccionada);
    },
  },
};
</script>

