<template>
  <div  class="examen">

    <div class="background-imagen">
      <div class="contenedort">
  
        <div class="epic-text">
          <p class="epic-title"><strong>EPIC TEST</strong></p>
        </div>
        <h1 class="ready-go">
          <span class="ready">Ready? </span>
          <span class="go">Go!</span>
        </h1>
        <div class="additional-text">
          <p>
            Estás a solo un paso de poner a prueba tu nivel de inglés y conseguir tu
            propio Certificado. Recuerda tener listos tus auriculares o altavoces,
            un micrófono y conexión a internet
          </p>
          <br>
          <p style='font-family: "pbold", sans-serif; font-weight: 500;'><strong>¡A por todas y buena suerte!</strong></p>
        </div>
        <div class="start-button-container">
          <router-link to="/preguntalargo">
            <button class="start-button">EMPEZAR PRUEBA</button>
          </router-link>
        </div>
      </div>
      
    </div>

   
  </div>

    <PieDePagina/>


</template>

<script lang="ts">
import {Options, Vue } from "vue-class-component";
import PieDePagina from "../components/PieDePagina.vue";
@Options({
  components: {
    PieDePagina,
  },
})

export default class ExamenVIew extends Vue {
   mounted() {
    // Establecer el overflow del body en 'hidden' cuando se monta el componente
  }
}
</script>


<style >
.contenedort{
  padding-top: 120px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
 
}
.start-button {
  background-color: var(--secondary-color);
  font-weight: 500;
  font-style: bold;
  color: white;
  font-size: 24px;
  padding: 15px 30px;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  font-family: "pbold", sans-serif;
}
.additional-text {
  font-weight: 300;
  text-align: center;
  margin: 0 20%; /* Agrega margen de 20px a los lados */
  margin-bottom: 50px;
  font-family: "plight", sans-serif;
}




.background-imagen {
  background: url("../../public/img/Sin título-2.png") center center no-repeat; 
  background-size: cover;
  height: 85.9vh;
  width: 100vw;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
 
  
  flex-direction: column;
 
  
}

.epic-text {
  font-size: 30px;
  color: var(--primary-color);
  text-align: center;
  font-family: "pmedium", sans-serif;
   
}

.ready-go {
  font-size: 2em;
  text-align: center;
   margin-bottom: 50px;
   font-weight: 900;
   color: var(--tertiary-color);
   font-family: "pblack", sans-serif;
}
.examen{
  position: relative;
}
.ready {
  font-weight: bold;
    font-weight: 900;
}
.go{
      background-image: linear-gradient(120deg, var(--primary-color) 0%, var(--primary-color) 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.5em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;
}
.underline {
  border-bottom: 2px solid var(--primary-color);
  margin: 10px auto;
  width: 50%;
}



.start-button-container {
  text-align: center;
}


</style>
