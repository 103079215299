<style>
@import '../assets/css/home.css';
</style>
<template>


  <br/>
  <div class="nivel-container">
    <div class="titulo-nivel">
      <p class="titulo" style="color: var(--primary-color)">
        ¿QUIERES SABER QUÉ NIVEL TIENES?
      </p>
      <h1 style="font-weight: 800; color: var(--tertiary-color)">
        ¡Haz la
        <span class="subrayado">prueba de nivel</span>
        ahora y gratis!
      </h1>
    </div>

    <div class="cuadros-container">
      <div class="cuadro">
        <div class="contenido">
          <h2 class="subtitulo-cuadro">Quick check</h2>
          <p class="contenido-cuadro">TU PRUEBA DE NIVEL MÁS RÁPIDA</p>
          <div class="linea1"></div>
          <p class="texto">
            <!-- Reducir tamaño de fuente -->
            Conoce de forma rápida y totalmente gratis cuál es tu nivel de
            inglés.
          </p>
          <div class="icono-reloj">
            <span
              class="mdi mdi-clock-time-nine-outline reloj"
            
            ></span>
            <h1 class="numero_reloj">15</h1>
            <p style="font-size: 21px; color: var(--tertiary-color)">
              MINUTOS
            </p>
            <!-- Reducir tamaño de fuente -->
          </div>
          <router-link to="/examencorto">
            <button class="home-boton-redondeado1">
              <!-- Reducir tamaño del botón -->
              <strong>HACER TEST QUICK CHECK</strong>
            </button>
          </router-link>
          <br />
          <br />
          <a class="masinfo" href="https://kingscorner.es/"
            >Más información</a
          >
          <!-- Reducir tamaño de fuente -->
        </div>
      </div>

      <div class="cuadro">
        <div class="contenido">
          <h2 class="subtitulo-cuadro2">Epic test</h2>
          <p class="contenido-cuadro2">UNA PRUEBA DE NIVEL PARA SUPERARTE</p>
          <div class="linea2"></div>
          <p class="texto" >
            <!-- Reducir tamaño de fuente -->
            Ponte a prueba, averigua tu nivel de inglés y consigue un diploma
            certificado por nosotros.
          </p>
          <div class="icono-reloj">
            <span
              class="mdi mdi-clock-time-nine-outline reloj"
              
            ></span>
            <h1 class="numero_reloj" >50</h1>
            <p style="font-size: 21px; color: var(--tertiary-color)">
              MINUTOS
            </p>
            <!-- Reducir tamaño de fuente -->
          </div>
          <router-link to="/examenlargo">
            <button class="home-boton-redondeado2">
              <strong>HACER TEST EPIC TEST</strong>
            </button>
          </router-link>
          <br />
          <br />
          <a class="masinfo" href="https://kingscorner.es/"
            >Más información</a
          >
          <!-- Reducir tamaño de fuente -->
        </div>
      </div>
    </div>
  </div>
  <div class="rectangulo-fondo"></div>
</template>

<script>
export default {
  name: "NivelComponent",
};
</script>

