<template>
  <div class="home-container">
    <Home/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Home from '../components/Home.vue'
 
@Options({
  components: {
    Home,
  },
})

export default class HomeView extends Vue {
  mounted() {
    // Establecer el overflow del body en 'hidden' cuando se monta el componente
   

    // Agregar el event listener para controlar el overflow del body
    window.addEventListener('resize', this.handleBodyOverflow);
    // Llamar a la función para establecer el overflow inicial
    this.handleBodyOverflow();
  }

  beforeDestroy() {
    // Eliminar el event listener cuando se destruye el componente
    window.removeEventListener('resize', this.handleBodyOverflow);
   
  }
  handleBodyOverflow() {
    // Obtener el ancho de la pantalla
    const screenWidth = window.innerWidth;
    // Aplicar el estilo correspondiente al overflow dependiendo del ancho de la pantalla
    document.body.style.overflow = screenWidth >= 845 ? 'hidden' : 'auto';
  }

  
}
</script>
