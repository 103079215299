<style>
@import url('../assets/css/start.css');
</style>
<template>
  <div class="pregunta-completar">
    <p class="grammar">AYÚDANOS A CONOCERTE UN POCO MÁS…</p>
    <h2>¿Cuál es tu principal motivación para aprender inglés?</h2>
    <div class="input-container">
      <button @click="changeComponent" class="boton">
        <i class="mdi mdi-check-circle-outline icono"></i>
        <strong>
          Mi motivación es personal y estoy interesado en hacer un curso
          online</strong
        >
      </button>
      <button @click="changeComponent" class="boton">
        <i class="mdi mdi-monitor icono"></i>
        <strong
          >Ya soy alumno de Kings Corner o lo voy a ser y estoy interesado en
          conocer mi nivel actual</strong
        >
      </button>
      <button @click="changeComponent" class="boton">
        <i class="mdi mdi-seal-variant icono"></i>
        <strong>Quiero conseguir un certificado oficial</strong>
      </button>
    </div>
  </div>
</template>

<script>
import BarraProgreso from "./BarraProgreso.vue";
export default {
  props: {
    changeComponent: Function,
  },
  components: {
    BarraProgreso,
  },
};
</script>
