<style>
@import url('../assets/css/cabecera.css');
</style>
<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img :src="logo" alt="Logo" class="logo" />
      </router-link>

    </div>
  </nav>

</template>

<script>
import tema from "../../public/data/data.json";

export default {
  name: "Cabecera",
  props: {
    logo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dropdownOpen: false
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    }
  },

  computed: {
   
  },
};
</script>
