<style>
   @import url('../assets/css/writing.css');
</style>
<template>
 

  <div class="pregunta-completar">
    <h2 class="grammar">WRITTING...</h2>
    <div  :style="{ fontSize: fontSize + 'px' }">
        {{ planteamiento }}
      </div>
    <p class="pregunta">{{ pregunta }}</p>
    <div class="input-container">
      <input type="text" v-model="respuesta" class="textbox" />
      <button @click="enviarRespuesta" class="boton">Enviar</button>
    </div>
  </div>
</template>

<script>
import BarraProgreso from "../components/BarraProgreso.vue";
export default {
  props: {
    pregunta: String,
    respuestas: Array,
    audio: String,
    changeComponent: Function,
  },
  components: {
    BarraProgreso,
  },
  data() {
    return {
      respuesta: '' // Variable para almacenar la respuesta del usuario
    };
  },
  methods: {
    enviarRespuesta() {
      // Emitir el evento con la respuesta ingresada por el usuario
      this.$emit('respuestaSeleccionada', this.respuesta);
    }
  }
};
</script>
