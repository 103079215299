import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ExamenView from '../views/ExamenVIew.vue';
import PreguntaView from '../views/PreguntaView.vue';
import ExamenCortoView from '../views/ExamenCortoView.vue';
import PreguntaCortaView from '../views/PruebaCortaView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/examenlargo',
    name: 'Examen',
    component: ExamenView
  },
  {
    path: '/preguntalargo',
    name: 'Examne Largo',
    component: PreguntaView
  },
  {
    path: '/examencorto',
    name: 'Examenc',
    component: ExamenCortoView
  },
  {
    path: '/preguntacorta',
    name: 'Examen corto',
    component: PreguntaCortaView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;

