<style>
  @import url('../assets/css/circulotiempo.css');
</style>
<template>
  <div class="circular-countdown">
    <svg width="40px" height="40px" viewBox="0 0 100 100">
      <circle class="circle-bg" cx="50" cy="50" r="45" />
      <circle
        class="circle-progress"
        cx="50"
        cy="50"
        r="45"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="dashoffset"
      />
    </svg>
    <text class="countdown-text">{{ formattedTime }}</text>
  </div>
</template>

<script>
export default {
  props: {
    duration: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      currentTime: this.duration,
      circumference: 2 * Math.PI * 45,
      timer: null
    };
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.currentTime / 60);
      const seconds = this.currentTime % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    dashoffset() {
      return this.circumference * (1 - this.currentTime / this.duration);
    }
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        if (this.currentTime > 0) {
          this.currentTime--;
        } else {
          clearInterval(this.timer);
          this.$emit('timeout'); // Emit timeout event
        }
      }, 1000);
    }
  },
  mounted() {
    this.startTimer();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

