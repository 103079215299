<template>
    <Cabecera :logo="tema.logo"/>
    <main>
      <router-view />
    </main>
    
</template>

<script>
import Cabecera from "./components/Cabecera.vue";
import PieDePagina from "./components/PieDePagina.vue";
import tema from "../public/data/data.json";
import '@mdi/font/css/materialdesignicons.min.css'


export default {
  components: {
    Cabecera,
    PieDePagina,
  },
  mounted() {
    // Aplica los estilos CSS basados en el tema
    const root = document.documentElement;
    root.style.setProperty('--primary-color', tema.color_primario);
    root.style.setProperty('--secondary-color', tema.color_secundario);
    root.style.setProperty('--tertiary-color', tema.color_terciario);
  },
  data() {
    return {
      tema: tema,
    };
  }
};
</script>

<style>
#app {
  width: 100vw;
  min-height: 100vh;
  
}


</style>
