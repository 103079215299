<style>
  @import url('../assets/css/listen.css');

</style>
<template>
 

  <div class="seleccion-multiple-audio">
    <br/>
       <h2 class="listening">LISTENING...</h2>
       <div  :style="{ fontSize: fontSize + 'px' }">
        {{ planteamiento }}
      </div>
    <p class="pregunta" v-if="pregunta">
      {{ pregunta }}
    </p>
    <audio controls>
      <source :src='audio' type="audio/mpeg" />

    </audio>
    
    <div class="opciones">
      <button class="opcion" v-for="(opcion, index) in preguntasAleatorias" :key="index" @click="seleccionarRespuesta(opcion)">
       {{ opcion }} 
      </button>
    </div>
  </div>
</template>

<script>
import BarraProgreso from "../components/BarraProgreso.vue";
export default {
  props: {
     pregunta: String,
     planteamiento: String,
    changeComponent: Function,
    respuestas: Array,
    audio: String,
  },
  components: {
    BarraProgreso,
  },
   data() {
    return {
      preguntasAleatorias: [] 
    };
  },
created() {
   
    this.generarOpcionesAleatorias();
  },
  methods: {
    generarOpcionesAleatorias() {
      // Copiar y mezclar las respuestas para mostrarlas en orden aleatorio
      this.preguntasAleatorias = [...this.respuestas].sort(() => Math.random() - 0.5);
    },
    seleccionarRespuesta(respuestaSeleccionada) {
      // Emitir el evento para enviar la respuesta seleccionada a la vista padre
      this.$emit('respuestaSeleccionada', respuestaSeleccionada);
    }
  }
};
</script>
