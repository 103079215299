<style>
  @import url('../assets/css/finalizado.css');
</style>
<template>
  <br />
  <div class="container">
    <p class="titulo"><strong> QUICK CHECK</strong></p>
    <div class="contenido">
      <h1>
        <span style="font-family: 'pblack', sans-serif; font-weight: 900"
          >Well
        </span>
        <span class="done" style="font-weight: 900">done!</span>
      </h1>
      <div class="niveles">
        <div class="sellos">
          <div>
            <img
              v-if="pregunta.includes('A1')"
              src="img/niveles/a1color.svg"
              alt=""
            />
            <img v-else src="img/niveles/a1gris.svg" alt="" />
          </div>
          <div>
            <img
              v-if="pregunta.includes('A2')"
              src="img/niveles/a2color.svg"
              alt=""
            />
            <img v-else src="img/niveles/a2gris.svg" alt="" />
          </div>
          <div>
            <img
              v-if="pregunta.includes('B1')"
              src="img/niveles/b1color.svg"
              alt=""
            />
            <img v-else src="img/niveles/b1gris.svg" alt="" />
          </div>
          
          <div>
            <img
              v-if="pregunta.includes('B2')"
              src="img/niveles/b2color.svg"
              alt=""
            />
            <img v-else src="img/niveles/b2gris.svg" alt="" />
          </div>
          <div>
            <img
              v-if="pregunta.includes('C1')"
              src="img/niveles/c1color.svg"
              alt=""
            />
            <img v-else src="img/niveles/c1gris.svg" alt="" />
          </div>
          <div>
            <img
              v-if="pregunta.includes('C2')"
              src="img/niveles/c2color.svg"
              alt=""
            />
            <img v-else src="img/niveles/c2gris.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="descripcion-nivel" style="font-family: 'plight', sans-serif">
      <p>
        En este nivel de
        <strong style="font-family: 'pbold', sans-serif"
          >Inglés {{ pregunta }}</strong
        >
        aprenderás a hablar de temas que te resulten familiares, leer textos
        sencillos, escribir correos electrónicos, etc. En resumen, podrás
        comunicarte de manera sencilla, pero con coherencia.
      </p>
    </div>
    <div>
      <Radar :data="datos" :options="options" />
    </div>

    <div class="logro-container">
      <div class="texto-info">
        <div class="logro-text">
          <div>
             <p class="logro-message"><strong> ¡LO HAS CONSEGUIDO!</strong></p>
          <h1 class="logro-subtitle">Proficiency in English</h1>
          <button class="descargar-boton">
            <strong>Descargar</strong>
          </button>

          </div>
         
          
        </div>
      </div>
      <div class="imagen-info">
        <img class="imagen-container" src="img/certificado.svg" alt="" />
      </div>
    </div>

    <div class="informacion-adicional">
      <br />
      <span class="informacion-texto">
        <strong>Y AHORA QUE YA SABES TU NIVEL...</strong>
      </span>
      <h1
        class="elige-texto"
        style="font-weight: 900; color: var(--tertiary-color)"
      >
        ¡Elige tu convocatoria y <span class="done">certifícate!</span>
      </h1>
      <div class="columnas-info">
        <div>
          <img class="imagen-container" src="img/oxford.svg" alt="" />
        </div>
        <div class="texto-info">
          <p class="info-destacada">
            <strong style="font-family: 'pbold', sans-serif"
              >Examen oficial de Oxford University Press</strong
            >
          </p>
          <p style="font-family: 'plight', sans-serif">
           Obtener el certificado oficial de la Universidad de Oxford es una gran oportunidad, 
           ya que no solo valida tu competencia lingüística de manera segura y fiable, sino que también
            puede abrir numerosas puertas en tu carrera.
            ¡Certifícate oficialmente con Kings Corner!
          </p>
        </div>
      </div>
      <button class="ver-convocatorias" @click="abrirEnlace">
        Ver convocatorias
      </button>
    </div>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "vue-chartjs";
import tema from "../../public/data/data.json";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
let numero = Array.from(
  { length: 5 },
  () => Math.floor(Math.random() * 100) + 1
);
let nuevoColor = tema.color_primario.replace(
  /(rgba\([^)]+,\s*)1(\))/,
  "$10.3$2"
);
console.log(nuevoColor);
var datos = null

export const options = {
  responsive: true,
  maintainAspectRatio: false,
   plugins: {
      legend: {
        position: 'top' ,
        display: false,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },

};

export default {
  props: {
    pregunta: {
      type:String,
    default: 'C1'},
     audio: {
      type:String,
    default: {
  grammar: 24,
  vocabulary: 24,
  listening: 25,
  writing: 23,
  reading: 29
}},
  },
  components: {
    Radar,
  },
  data() {
    return {
      enlace: "",
      datos,
      options,
    };
  },
  computed: {},
  methods: {
    organizar(organiza){

      const orderedKeys = ['writing', 'listening', 'reading', 'grammar', 'vocabulary'];

      const orderedAudioArray = orderedKeys.map(key => organiza[key]);

      console.log(orderedAudioArray);
     
      return orderedAudioArray;
    },
    abrirEnlace() {
      if (this.enlace !== "") {
        window.open(this.enlace, "_blank");
      }
    },

    generar() {
      console.log(this.pregunta);
      if (this.pregunta.includes("A1")) {
        this.enlace =
          "https://englishschool.kingscorner.es/cursos-online/ingles-a1-online/";
      } else if (this.pregunta.includes("A2")) {
        this.enlace =
          "https://englishschool.kingscorner.es/cursos-online/ingles-a2-online/";
      } else if (this.pregunta.includes("B1")) {
        this.enlace =
          "https://englishschool.kingscorner.es/cursos-online/ingles-b1-online/";
      } else if (this.pregunta.includes("B2")) {
        this.enlace =
          "https://englishschool.kingscorner.es/cursos-online/ingles-b2-online/";
      } else if (this.pregunta.includes("C1")) {
        this.enlace =
          "https://englishschool.kingscorner.es/cursos-online/ingles-c1-online/";
      } else if (this.pregunta.includes("C2")) {
        this.enlace =
          "https://englishschool.kingscorner.es/cursos-online/ingles-c1-online/";
      } else {
        this.enlace =
          "https://englishschool.kingscorner.es/certifica-tu-ingles/";
      }
    },
  },
  created() {
    
    document.body.style.overflow = "auto";
     this.datos = {
      labels: ["Writting", "Listening", "Speaking", "Grammar", "Vocabulary"],
      datasets: [
        {
          label: "",
          backgroundColor: nuevoColor,
          borderColor: tema.color_primario,
          pointBackgroundColor: tema.color_primario,
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: tema.color_primario,
          data: this.organizar(this.audio) ,
        },
      ],
    };
    this.generar();
  },
};
</script>