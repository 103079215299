<template>
  <div  class="examen">

    <div class="background-imagen">
      <div class="contenedort">
  
        <div class="epic-text">
          <p class="epic-title"><strong>EPIC TEST</strong></p>
        </div>
        <h1 class="ready-go">
          <span class="ready">Ready? </span>
          <span class="go">Go!</span>
        </h1>
        <div class="additional-text">
          <p>
            Estás a solo un paso de poner a prueba tu nivel de inglés y conseguir tu
            propio Certificado. Recuerda tener listos tus auriculares o altavoces,
            un micrófono y conexión a internet
          </p>
          <br>
          <p style='font-family: "pbold", sans-serif;'><strong>¡A por todas y buena suerte!</strong></p>
        </div>
        <div class="start-button-container">
          <router-link to="/preguntacorta">
            <button class="start-button">EMPEZAR PRUEBA</button>
          </router-link>
        </div>
      </div>
      
    </div>

   
  </div>

 <PieDePagina/>

</template>

<script lang="ts">
import {Options, Vue } from "vue-class-component";
import PieDePagina from "../components/PieDePagina.vue";
  
@Options({
  components: {
    PieDePagina,
  },
})

export default class ExamenVIew extends Vue {
   mounted() {
    // Establecer el overflow del body en 'hidden' cuando se monta el componente
   

    // Agregar el event listener para controlar el overflow del body
    window.addEventListener('resize', this.handleBodyOverflow);
    // Llamar a la función para establecer el overflow inicial
    this.handleBodyOverflow();
  }

  beforeDestroy() {
    // Eliminar el event listener cuando se destruye el componente
    window.removeEventListener('resize', this.handleBodyOverflow);
   
  }

  // Función para controlar el overflow del body dependiendo del ancho de la pantalla
  handleBodyOverflow() {
    // Obtener el ancho de la pantalla
    const screenWidth = window.innerWidth;
    // Aplicar el estilo correspondiente al overflow dependiendo del ancho de la pantalla
    document.body.style.overflow = screenWidth >= 845 ? 'hidden' : 'hidden';
  }
}
</script>


<style >

@media screen and (min-width: 845px) {
 .contenedort{
  padding-top: 9vh;
}
.start-button {
  background-color: var(--secondary-color);
  color: white;
  font-size: 1.5em;
  padding: 15px 30px;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  font-family: "pbold", sans-serif;
}
.additional-text {
  text-align: center;
  margin: 0 30%; /* Agrega margen de 20px a los lados */
  margin-bottom: 50px;
  font-family: "plight", sans-serif;
}


}
@media screen and (max-width: 844px) {
 .contenedort{
  padding-top: 0;
}
 .start-button {
  background-color: var(--secondary-color);
  color: white;
  font-size: 1.5em;
  padding: 1vh 5vh;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  font-family: "pbold", sans-serif;
}
.additional-text {
  text-align: center;
  margin: 0 30%; /* Agrega margen de 20px a los lados */
  margin-bottom: 4vh;
  font-family: "plight", sans-serif;
}

}



.background-imagen {
  background: url("../../public/img/Sin título-2.png") center center no-repeat; 
  background-size: cover;
  height: 86vh;
  width: 100vw;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
 
  
  flex-direction: column;
 
  
}

.epic-text {
  color: var(--primary-color);
  text-align: center;
  font-family: "pmedium", sans-serif;
   
}

.ready-go {
  font-size: 2em;
  text-align: center;
   margin-bottom: 50px;
   font-weight: 900;
   color: var(--tertiary-color);
   font-family: "pblack", sans-serif;
}

.ready {
  font-weight: bold;
    font-weight: 900;
}
.go{
      background-image: linear-gradient(120deg, var(--primary-color) 0%, var(--primary-color) 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.5em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;
}
.underline {
  border-bottom: 2px solid var(--primary-color);
  margin: 10px auto;
  width: 50%;
}



.start-button-container {
  text-align: center;
}


</style>
