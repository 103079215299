<style>
  @import url('../assets/css/piedepagina.css');
</style>
<template>
  <footer class="footer-container"></footer>
</template>

<script>
export default {
  name: "PieDePagina",
};
</script>

